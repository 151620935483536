@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400&display=swap");

.whyUsOverflow>*>div {
    height: 80vh !important;
}

.whyUsOverflow>*>div>* {
    display: block !important;
    transform: translate(499.179px, 0px);
}

.AccordionButton>div>div>h2>button:hover {
    background: none
}

@media only screen and (max-width: 720px) {
    .whyUsOverflow>*>div {
        z-index: 2;
    }
}